exports.components = {
  "component---src-components-blog-page-blog-detail-js": () => import("./../../../src/components/blogPage/blogDetail.js" /* webpackChunkName: "component---src-components-blog-page-blog-detail-js" */),
  "component---src-components-blog-page-blog-list-js": () => import("./../../../src/components/blogPage/blogList.js" /* webpackChunkName: "component---src-components-blog-page-blog-list-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-certifications-js": () => import("./../../../src/pages/certifications.js" /* webpackChunkName: "component---src-pages-certifications-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-healthcare-js": () => import("./../../../src/pages/industries/healthcare.js" /* webpackChunkName: "component---src-pages-industries-healthcare-js" */),
  "component---src-pages-industries-index-js": () => import("./../../../src/pages/industries/index.js" /* webpackChunkName: "component---src-pages-industries-index-js" */),
  "component---src-pages-industries-insurance-js": () => import("./../../../src/pages/industries/insurance.js" /* webpackChunkName: "component---src-pages-industries-insurance-js" */),
  "component---src-pages-industries-public-utility-js": () => import("./../../../src/pages/industries/public-utility.js" /* webpackChunkName: "component---src-pages-industries-public-utility-js" */),
  "component---src-pages-industries-retail-and-e-commerce-js": () => import("./../../../src/pages/industries/retail-and-e-commerce.js" /* webpackChunkName: "component---src-pages-industries-retail-and-e-commerce-js" */),
  "component---src-pages-industries-social-media-js": () => import("./../../../src/pages/industries/social-media.js" /* webpackChunkName: "component---src-pages-industries-social-media-js" */),
  "component---src-pages-industries-technology-js": () => import("./../../../src/pages/industries/technology.js" /* webpackChunkName: "component---src-pages-industries-technology-js" */),
  "component---src-pages-industries-travel-hospitality-and-cargo-js": () => import("./../../../src/pages/industries/travel-hospitality-and-cargo.js" /* webpackChunkName: "component---src-pages-industries-travel-hospitality-and-cargo-js" */),
  "component---src-pages-industries-video-commerce-js": () => import("./../../../src/pages/industries/video-commerce.js" /* webpackChunkName: "component---src-pages-industries-video-commerce-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-data-insight-services-js": () => import("./../../../src/pages/services/data-insight-services.js" /* webpackChunkName: "component---src-pages-services-data-insight-services-js" */),
  "component---src-pages-services-devops-services-js": () => import("./../../../src/pages/services/devops-services.js" /* webpackChunkName: "component---src-pages-services-devops-services-js" */),
  "component---src-pages-services-digital-marketing-services-js": () => import("./../../../src/pages/services/digital-marketing-services.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-services-js" */),
  "component---src-pages-services-ecommerce-services-js": () => import("./../../../src/pages/services/ecommerce-services.js" /* webpackChunkName: "component---src-pages-services-ecommerce-services-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-seo-services-js": () => import("./../../../src/pages/services/seo-services.js" /* webpackChunkName: "component---src-pages-services-seo-services-js" */),
  "component---src-pages-services-software-development-services-js": () => import("./../../../src/pages/services/software-development-services.js" /* webpackChunkName: "component---src-pages-services-software-development-services-js" */),
  "component---src-pages-services-web-design-development-services-js": () => import("./../../../src/pages/services/web-design-development-services.js" /* webpackChunkName: "component---src-pages-services-web-design-development-services-js" */),
  "component---src-pages-terms-n-conditions-js": () => import("./../../../src/pages/terms-n-conditions.js" /* webpackChunkName: "component---src-pages-terms-n-conditions-js" */)
}

